.img-holder
{
    position: absolute;
    top: -70px;
    left: 0px;
    right: 0px;
}
.img-holder img
{
    width: 150px;
    height: 150px;
    border-radius: 100%;
}
.card-show .box .box-inner-2 .name {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 2px;
    color: #ffffff;
}
.card-show .box .box-inner-2 .company {
    font-size: 18px;
    font-weight: 100;
    margin-bottom: 2px;
    color: #ffffff;
}
.border {
    border: 1px solid #dee2e6 !important;
}
.border-right {
    border-right: 1px solid #dee2e6 !important;
}
.align-items-center {
    align-items: center !important;
}
.justify-content-center {
    justify-content: center !important;
}
.card-show .box .contact-list-2 {
    text-align: left;
    list-style: none;
    margin: 0;
    padding: 0 20px 20px;
    overflow: hidden;
    display: block;
}
.card-show .box .social-list-2 {
    list-style: none;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
    padding-top: 1px;
    display: grid;
    grid-template-columns: repeat(7, auto);
    justify-content: center;
}
.card-show .box {
    position: relative;
    border-radius: 8px;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.1);
    background: #fff;
    text-align: center;
    box-sizing: border-box;
    padding: 20px 0;
}
.card-show .box .contact-list-2 li {
    background: #ffffff;
    margin-bottom: 0.7rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-bottom: 1px solid #00336659;
}
.card-show  li a
{
    text-decoration: none;
}
.contact-list li a, .website, .btn, .card-show p a {
    color: #225662 !important;
}
.card-show .btns-holder a {
    color: white !important;
    font-size: 16px;
}
.card-show .box .social-list-2 li a {
    display: block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 28px;
    text-decoration: none;
    color: #fff;
    transition: 0.2s;
    background: linear-gradient(90deg, rgb(1, 78, 118) 0%, rgb(111, 195, 212) 127.93%, rgba(1, 78, 118, 0) 201.42%);
    border-radius: 50%;
}
.btn-sm, .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.7875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    background: linear-gradient(90deg, rgb(1, 78, 118) 0%, rgb(111, 195, 212) 127.93%, rgba(1, 78, 118, 0) 201.42%);
}
.card-show .box .contact-list-2 li a i {
    display: inline-block;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
    width: 22px;
}
.card-show .box .social-list-2 li {
    float: left;
    display: block;
    margin: 10px;
}
body{
    background-color: rgb(242, 242, 242);
}
.card-show {
    height: 100vh;
}
.text-right {
    text-align: right !important;
}
.ml-auto, .mx-auto {
    margin-left: auto !important;
}